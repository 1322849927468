/**
 * @file
 * This file lists/imports the extends that we have created for the theme.
 */

.mobile-padding {
  padding: 0 1rem;
  @include breakpoint($large) {
    padding: 0;
  }
}

%centered-xy {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  text-align: center;
}

.centered-xy {
  @extend %centered-xy;
}
