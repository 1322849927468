/**
 * @file
 * This file that creates the CSS for the header section.
 */

.header {
  background: $c-primary;
  height: 60px;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1000;
  @include breakpoint($large) {
    height: 80px;
  }
  a {
    color: $white;
    padding-bottom: 8px;
    text-decoration: none;
  }
}

.header .container {
  @extend .mobile-padding;
  display: flex;
  position: relative;
}

.header .site-name {
  align-items: center;
  display: flex;
  font-size: 120%;
  height: 60px;
  margin-top: 6px;
  position: relative;
  width: 67%;
  z-index: 10;
  @include breakpoint($large) {
    font-size: $h1-font-size;
    height: 80px;
    width: 30%;
  }
}

.header .nav {
  position: absolute;
  right: 0;
  width: 33%;
  &.active {
    height: 100vh;
    overflow-y: scroll;
    width: 100%;
  }
  @include breakpoint($large) {
    width: 70%;
  }
}
