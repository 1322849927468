/**
 * @file
 * This file is used to style the slider.
 */

.flexslider {
  height: 0;
  padding-bottom: 42.37%; // ratio of height to width
  overflow: hidden; // Makes sure the content underneath it doesn't disappear behind it
  position: relative;
}

.flexslider ul {
  margin: 0;
  padding: 0;
}

.flexslider ul li {
  left: 0;
  position: absolute;
  top: 0;
}

.flexslider ul li img {
  width: 100%;
}
