/**
 * @file
 * This file is used to style the gallery.
 */

.gallery {
  @include breakpoint($medium) {
    display: flex;
    flex-wrap: wrap;
  }
}

.gallery .gallery--item {
  @include breakpoint($medium) {
    width: 50%;
  }
  @include breakpoint($large) {
    width: 33%;
  }
}
.gallery .gallery--button {
  border: 0;
  background: transparent;
  margin: 0;
  padding: 0;
  width: 100%;
  img {
    width: 100%;
  }
}

.gallery--popup-item {
  background: rgba($c-grey--light, .95);
  bottom: 0;
  display: none;
  height: 100%;
  left: 0;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 10000;
}

.gallery--popup--image {
  @include breakpoint($medium) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    text-align: center;
  }
}

.gallery--popup--close {
  background: $c-primary;
  bottom: 1rem;
  color: $white;
  border: 0;
  font-weight: bold;
  padding: 1rem;
  position: absolute;
  right: 1rem;
  text-transform: uppercase;
}
