/**
 * @file
 * This file adds default styling for images.
 */

img {
  height: auto;
  max-width: 100%;
}

.hero-image img {
  width: 100%;
}
