/**
 * @file
 * This file that creates the CSS for the nav secion.
 */

.menu-toggle {
  background: $c-primary-dark;
  border: none;
  color: $white;
  cursor: pointer;
  float: right;
  font-size: 1rem;
  height: 60px;
  padding: 0 1rem;
  text-transform: uppercase;
  @include breakpoint($large) {
    height: 80px;
  }
}

.main-menu {
  background: $c-primary-dark;
  display: none;
  clear: both;
  margin: 0;
  padding: 0;
  @include breakpoint($large) {
    align-items: center;
    background: $c-primary;
    display: flex !important; // Needed to override the display: block from .show()
    height: 80px;
    justify-content: space-evenly;
  }
}

.main-menu .menu-item {
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: right;
}

.main-menu .menu-item a {
  display: inline-block;
  padding: 1rem;
  @include breakpoint($large) {
    border-bottom: 2px solid $c-primary;
    &:focus,
    &:hover {
      border-bottom: 2px solid $white;
    }
  }
}
