/**
 * @file
 * This file lists/imports the fonts that we are going to use in the theme.
 */

 @import url('https://fonts.googleapis.com/css?family=Roboto:300');
 @import url('https://fonts.googleapis.com/css?family=Gabriela');

//
// Font faces, stacks and sizes.
//

$base-font-size:   16px; // The font size set on the root html element.
$base-line-height: 1.5; // This line-height determines the basic unit of vertical rhythm.

$h1-font-size: 2    * $base-font-size;
$h2-font-size: 1.5  * $base-font-size;
$h3-font-size: 1.25 * $base-font-size;
$h4-font-size: 1.15 * $base-font-size;
$h5-font-size: 1    * $base-font-size;
$h6-font-size: 0.85 * $base-font-size;

// First, let's create some font stacks.
$helvetica: Helvetica, Arial, "Nimbus Sans L", sans-serif;

// Now create some variables for the font stacks we want to use on this site.
$font1: 'Roboto', $helvetica;
$font2: 'Gabriela', $font1;
