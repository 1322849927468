/*
SCSS variables are information about icon's compiled state, stored under its original file name

.icon-home {
  width: $icon-home-width;
}

The large array-like variables contain all information about a single icon
$icon-home: x y offset_x offset_y width height total_width total_height image_path;

At the bottom of this section, we provide information about the spritesheet itself
$spritesheet: width height image $spritesheet-sprites;
*/
$menu-toggle-close-name: 'menu-toggle-close';
$menu-toggle-close-x: 96px;
$menu-toggle-close-y: 53px;
$menu-toggle-close-offset-x: -96px;
$menu-toggle-close-offset-y: -53px;
$menu-toggle-close-width: 19px;
$menu-toggle-close-height: 20px;
$menu-toggle-close-total-width: 149px;
$menu-toggle-close-total-height: 96px;
$menu-toggle-close-image: '../images/sprites/sprite/sprite.png';
$menu-toggle-close: (96px, 53px, -96px, -53px, 19px, 20px, 149px, 96px, '../images/sprites/sprite/sprite.png', 'menu-toggle-close', );
$menu-toggle-small-name: 'menu-toggle-small';
$menu-toggle-small-x: 96px;
$menu-toggle-small-y: 0px;
$menu-toggle-small-offset-x: -96px;
$menu-toggle-small-offset-y: 0px;
$menu-toggle-small-width: 53px;
$menu-toggle-small-height: 53px;
$menu-toggle-small-total-width: 149px;
$menu-toggle-small-total-height: 96px;
$menu-toggle-small-image: '../images/sprites/sprite/sprite.png';
$menu-toggle-small: (96px, 0px, -96px, 0px, 53px, 53px, 149px, 96px, '../images/sprites/sprite/sprite.png', 'menu-toggle-small', );
$menu-toggle-name: 'menu-toggle';
$menu-toggle-x: 0px;
$menu-toggle-y: 0px;
$menu-toggle-offset-x: 0px;
$menu-toggle-offset-y: 0px;
$menu-toggle-width: 96px;
$menu-toggle-height: 96px;
$menu-toggle-total-width: 149px;
$menu-toggle-total-height: 96px;
$menu-toggle-image: '../images/sprites/sprite/sprite.png';
$menu-toggle: (0px, 0px, 0px, 0px, 96px, 96px, 149px, 96px, '../images/sprites/sprite/sprite.png', 'menu-toggle', );
$spritesheet-width: 149px;
$spritesheet-height: 96px;
$spritesheet-image: '../images/sprites/sprite/sprite.png';
$spritesheet-sprites: ($menu-toggle-close, $menu-toggle-small, $menu-toggle, );
$spritesheet: (149px, 96px, '../images/sprites/sprite/sprite.png', $spritesheet-sprites, );

/*
The provided mixins are intended to be used with the array-like variables

.icon-home {
  @include sprite-width($icon-home);
}

.icon-email {
  @include sprite($icon-email);
}
*/
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

/*
The `sprites` mixin generates identical output to the CSS template
  but can be overridden inside of SCSS

@include sprites($spritesheet-sprites);
*/
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}
