/**
 * @file
 * This file creates the styling for the body element.
 */

body {
  margin: 0;
  overflow-x: hidden;
  padding-top: 60px; // So the slider or main does not get hidden behind the header.
  @include breakpoint($large) {
    padding-top: 80px;
  }
}

body.noscroll {
  overflow: hidden;
}
