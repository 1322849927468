/**
 * @file
 * This file creates the styling for the a elements.
 */

a {
  color: $c-primary;
  text-decoration: none;
  &:focus,
  &:hover {
    text-decoration: underline;
  }
}
