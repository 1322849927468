/**
 * @file
 * This file creates the styling for the html element.
 */

html {
  font-family: $font1;
  font-size: $base-font-size;
  line-height: $base-line-height;
}
