/**
 * @file
 * This file creates the styling for the h elements.
 */

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  font-family: $font2;
}

h1, .h1 {
  font-size: $h1-font-size;
}
h2, .h2 {
  font-size: $h2-font-size;
}
h3, .h3 {
  font-size: $h3-font-size;
}
h4, .h4 {
  font-size: $h4-font-size;
}
h5, .h5 {
  font-size: $h5-font-size;
}
h6, .h6 {
  font-size: $h6-font-size;
}
